import { Button } from '@mui/material';
import { useDispatch } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { FC } from 'react';
import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleOAuth } from 'types/user';
import { googleLogin } from '../slice';
import axios from "axios";

const CLIENT_ID = import.meta.env.VITE_APP_GL_CLIENT_ID;

interface GoogleAuthType {
    inviterData?: string;
}
const GoogleAuthButton: FC<GoogleAuthType> = (props) => {
    const { inviterData } = props;

    const dispatch = useDispatch();
    const translatedWords = useApplyTranslation();

    const googleSuccess = async (res) => {
        try {
            const { data } = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                headers: { Authorization: `Bearer ${res.access_token}` },
            });

            console.log("Google User Data:", data);

            const request: GoogleOAuth = {
                email: data.email,
                token: res.access_token,
                firstName: data.given_name,
                lastName: data.family_name,
            };

            localStorage.setItem("google-token", JSON.stringify(res.access_token));

            if (request.email && request.token) {
                await dispatch(googleLogin(request, inviterData));
            }
        } catch (error) {
            console.error("Error fetching Google user data:", error);
        }
    };

    const googleFailure = (error) => {
        console.log("Google Sign-In Failed:", error);
        alert("Google sign-in failed. Please try again.");
    };

    const login = useGoogleLogin({
        onSuccess: googleSuccess,
        onError: googleFailure,
    });

    return (
        <Button
            fullWidth
            variant="contained"
            size="large"
            style={{ color: "#000", background: "#fff" }}
            onClick={() => login()} // New Login Function
            startIcon={<img src={"/static/google.png"} width="20px" height="20px" alt="google icon" />}
        >
            {translatedWords.signin_google}
        </Button>
    );
};

const GoogleAuth: FC<GoogleAuthType> = (props: GoogleAuthType) => (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
        <GoogleAuthButton {...props} />
    </GoogleOAuthProvider>
);

export default GoogleAuth;
